import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
import "../css/entry.css";
import { initCarousels } from "./components/carousel";
import { initForm } from "./components/form";
import { initHashScroll } from "./components/hash-scroll";
import { initJobs } from "./components/jobs";
import { initLightbox } from "./components/lightbox";
import { initMenu } from "./components/menu";
import { initScroll } from "./components/scroll";
import { initUsercentricsButtons } from "./components/usercentrics";
import { initVideo } from "./components/video";
import registerErrorReporting from "./error-reporting";

registerErrorReporting();
initUsercentricsButtons();
initCarousels();
initJobs();
initMenu();
initLightbox();
initHashScroll();
initForm();
initVideo();
initScroll();

import EmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from "embla-carousel";

const handleNavigation = (
  carousel: EmblaCarouselType,
  leftArrowElement: HTMLButtonElement | null,
  rightArrowElement: HTMLButtonElement | null
) => {
  if (!leftArrowElement || !rightArrowElement) return;

  leftArrowElement.disabled = !carousel.canScrollPrev();
  rightArrowElement.disabled = !carousel.canScrollNext();
};

const addCarousel = (
  emblaNode: HTMLElement,
  element: HTMLElement,
  options?: Partial<EmblaOptionsType>
) => {
  const carousel = EmblaCarousel(element, {
    align: "start",
    containScroll: "trimSnaps",
    ...options,
  });

  const leftArrowElement =
    emblaNode.querySelector<HTMLButtonElement>(".js-carousel-prev");
  const rightArrowElement =
    emblaNode.querySelector<HTMLButtonElement>(".js-carousel-next");

  leftArrowElement?.addEventListener("click", () => carousel.scrollPrev());
  rightArrowElement?.addEventListener("click", () => carousel.scrollNext());

  if (options?.loop) return;

  carousel.on("select", () =>
    handleNavigation(carousel, leftArrowElement, rightArrowElement)
  );
  carousel.on("init", () =>
    handleNavigation(carousel, leftArrowElement, rightArrowElement)
  );
};

export const initCarousels = () => {
  const arrowSliders =
    document.querySelectorAll<HTMLElement>(".js-embla-arrow");

  arrowSliders.forEach((arrowSlider) => {
    const emblaContainer =
      arrowSlider.querySelector<HTMLElement>(".embla__viewport");
    emblaContainer && addCarousel(arrowSlider, emblaContainer);
  });

  const arrowLoopSliders = document.querySelectorAll<HTMLElement>(
    ".js-embla-arrow-loop"
  );

  arrowLoopSliders.forEach((arrowLoopSlider) => {
    const emblaContainer =
      arrowLoopSlider.querySelector<HTMLElement>(".embla__viewport");
    emblaContainer &&
      addCarousel(arrowLoopSlider, emblaContainer, {
        loop: true,
      });
  });
};

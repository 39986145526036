export const initScroll = () => {
  const header = document.querySelector<HTMLElement>(".js-header");
  let lastScrollTop = 0;

  window.addEventListener("scroll", () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    header?.classList.toggle("show-header", scrollTop < lastScrollTop);

    lastScrollTop = scrollTop;
  });
};
